<template>
  <div>
    <QueryForm ref="form" v-model="form" :query-btn-loading="tableLoading" @query="handleQuery" @reset="resetClick">
      <el-form-item label="协议编号">
        <el-input v-model="form.agreementCode" />
      </el-form-item>
      <el-form-item label="合同编号">
        <el-input v-model="form.contractCode" />
      </el-form-item>
      <el-form-item label="合同类型">
        <Select
          v-model="form.contractType"
          :select-options="_getAllCommodityDict('SIGN_CONTRACT_TYPE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="采购主体">
        <Select
          v-model="form.cargoOwnerCode"
          api-key="cargoOwnerList"
          :configuration="{label:'cargoOwnerName',value:'cargoOwnerCode',key:'cargoOwnerCode'}"
          clearable
          multiple
          @responseData="val=>baseWareHouseData=val"
        />
      </el-form-item>
      <el-form-item label="印章类型">
        <Select
          v-model="form.signSealType"
          api-key="sealtype"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="供应商">
        <Select
          v-model="form.vendorId"
          api-key="vendorList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="状态">
        <Select
          v-model="form.agreementState"
          :select-options="_getAllCommodityDict('SIGN_STATE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="用印类别">
        <Select
          v-model="form.signPrintType"
          :select-options="_getAllCommodityDict('SIGN_PRINT_TYPE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="印章所属公司">
        <Select
          v-model="form.organizationCode"
          api-key="organization"
          clearable
        />
      </el-form-item>

      <el-form-item label="签署类型">
        <Select
          v-model="form.signSignatureType"
          :select-options="_getAllCommodityDict('SIGN_SIGNATURE_TYPE')"
          :configuration="optionsConfig"
          clearable
          multiple
        />
      </el-form-item>
    </QueryForm>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <el-button type="primary" @click="handleAdd">新增</el-button>
        <el-button type="primary" :disabled="!multipleSelectionTable.length" @click="handleApply">用印申请</el-button>
        <el-button type="primary" @click="handleDownload">下载</el-button>
      </el-col>
    </el-row>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="supplementColumns"
      :page-obj="pager"
      :check="true"
      :loading="tableLoading"
      @handleSelectionChange="val => {multipleSelectionTable=val}"
    >
      <el-table-column slot="number" label="关联采购订单数" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" :loading="scope.row.numberLoading" @click="showDeatail(scope.row)">{{ scope.row.purchaseOrderNumber }}</el-button>
        </template>
      </el-table-column>
      <el-table-column slot="operate" label="操作" align="center" fixed="right" width="150">
        <template slot-scope="scope">
          <el-button v-if="scope.row.agreementState==0" type="text" @click="deleteContract(scope.row.id)">删除</el-button>
          <!--  签署拒绝 审批拒绝 供应商已下载 供应商已盖章-->
          <el-button v-if="[5,8,1,2].includes(Number(scope.row.agreementState))" type="text" @click="validContract(scope.row)">作废</el-button>
          <!-- 签署拒绝 审批拒绝 待供应商重新上传 供应商已盖章 初始-->
          <el-button v-if="[0,2,3,8,5].includes(Number(scope.row.agreementState))" type="text" @click="uploadContract(scope.row)">上传</el-button>
        </template>
      </el-table-column>
    </Table>
    <div class="block">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>
    <PoDetail v-model="dialogContractVisible" :detail-table="contractDetails" :columns="contractPoDetails" :title="'PO详情'" />
    <DownloadFile v-model="dowmloadDialogVisible" :row="multipleSelectionTable[0]" :file-type-list="fileTypeList" :api="replenishContractDownload" />
    <Application v-model="applyDialogVisible" :receive-logs="multipleSelectionTable" :api="replenishCheckRefuse" :download="replenishContractDownload" @refresh="handleQuery" />
    <ContractType v-model="contractDialogVisible" :data="purchaseData" @refresh="handleQuery" />
    <UploadPdf :id="id" v-model="uploadDialogVisible" :api="contractUpload" :contract-type="3" @refresh="handleQuery" />

  </div>
</template>

<script>
import { replenishApproval, replenishCheckRefuse, replenishContractDownload, contractUpload, replenishContractPage, delContract, replenishContractDetail, cancelReplenishContract } from '@/api/scm-api'
import { supplementColumns, frameContractDetailscolumns, contractPoDetails } from '@/constant/tablecolumns'
import Table from '@/components/Table'
import Paging from '@/components/Pagination'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import QueryForm from '@/components/QueryForm'
import Application from '../components/Application'
import DownloadFile from '../components/DownloadFile'
import ContractType from '../components/ContractType'
import UploadPdf from '../components/UploadPdf'
import PoDetail from '../components/PoDetail'

export default {
  components: { PoDetail, Table, Paging, Select, QueryForm, Application, DownloadFile, ContractType, UploadPdf },
  mixins: [commodityInfoDict],
  data() {
    return {
      replenishApproval, replenishCheckRefuse,
      replenishContractDownload,
      contractDialogVisible: false,
      contractPoDetails,
      submitLoading: false,
      baseWareHouseData: [],
      form: { }, showRow: false, supplementColumns,
      tableLoading: false, tableData: [], pager: { size: 20, current: 1, total: 0 },
      contractDetails: [],
      frameContractDetailscolumns,
      dialogContractVisible: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      multipleSelectionTable: [],
      applyDialogVisible: false,
      dowmloadDialogVisible: false, contractUpload,
      value: '', uploadDialogVisible: false, id: undefined,
      fileTypeList: [{ label: '原始合同文件（未盖章）', value: 'originUrl' }, { label: '供应商单方盖章文件', value: 'unilateralUrl' }, { label: '双方盖章文件', value: 'multiUrl' }],
      purchaseData: { contractCode: '', file: '',
        notFufilledPoCodeList: [],
        frameworkContractExpiryDate: '', contractType: '', originSignDate: '' }
    }
  },
  computed: {
    queryParams() {
      // const obj = deepClone(this.form)
      // for (var i in obj) {
      //   if (Object.prototype.toString.call(obj[i]) === '[object Array]') {
      //     obj[i] = obj[i].toString()
      //   }
      // }
      return Object.assign({}, this.pager, this.form)
    },
    lengthFlag() {
      return this.multipleSelectionTable.length < 11
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
    if (this.$route.query.isPurchase) {
      this.contractDialogVisible = true
      const { purchaseOrderCode, purchaseOrderContractCode } = this.$route.query
      Object.assign(this.purchaseData, { agreementType: '2', contractCode: purchaseOrderContractCode, notFufilledPoCodeList: [purchaseOrderCode] })
    }
    this.handleQuery()
  },
  methods: {
    uploadContract({ id }) {
      this.id = id
      this.uploadDialogVisible = true
    },
    handleAdd() {
      this.contractDialogVisible = true
    },
    async handleDownload() {
      if (this.multipleSelectionTable.length === 1) {
        this.dowmloadDialogVisible = true
      } else {
        this.$message.warning('请勾选一条数据下载')
      }
    },
    handleApply() {
      const { cargoOwnerId, contractType, agreementType } = this.multipleSelectionTable[0]
      const cargoFlag = this.multipleSelectionTable.every(item => item.cargoOwnerId === cargoOwnerId &&
       item.contractType === contractType && item.agreementType === agreementType)
      const statusFlag = this.multipleSelectionTable.every(item => [2, 5, 8].includes(Number(item.agreementState)))
      const confirmFlag = this.multipleSelectionTable.some(item => [5, 8].includes(Number(item.agreementState)))
      if (!cargoFlag) {
        this.$message.warning('采购主体、关联合同类型、协议类型不一致，无法发起用印申请！')
        return
      }
      if (!statusFlag) {
        this.$message.warning('仅限状态为“供应商已盖章”、“审批拒绝”、“签署失败”发起用印申请')
        return
      }
      // 新增逻辑 只要选中了的数据中存在类型是框架合同的，就只能选一条数据
      const onlyOneData = this.multipleSelectionTable.some(item => item.contractType === 2)
      if (onlyOneData && this.multipleSelectionTable.length !== 1) return this.$message.warning('当选择的合同类型=“采购框架合同”时，仅允许选中一笔数据')

      if (confirmFlag) {
        this.lengthFlag ? this.$confirm(`${this.multipleSelectionTable.length === 1 ? '当前' : '存在'}合同此前用印申请被拒绝，请确认是否重新发起审批流程?`, '提示', {
          confirmButtonText: '确定',
          showCancelButton: true,
          type: 'warning'
        }).then(() => {
          this.applyDialogVisible = true
        }) : this.$message.warning('至多选择10条数据发起用印申请！')
      } else {
        this.lengthFlag ? this.applyDialogVisible = true : this.$message.warning('至多选择10条数据发起用印申请！')
      }
    },

    async   showDeatail(row) {
      try {
        this.$set(row, 'numberLoading', true)
        const { code, datas } = await replenishContractDetail(row.agreementCode)
        if (code === 0) {
          this.contractDetails = datas.map(item => { return { purchaseOrderCode: item } })
          this.dialogContractVisible = true
        }
      } finally {
        this.$set(row, 'numberLoading', false)
      }
    },
    validContract({ id }) {
      this.$prompt('请输入作废原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        inputPattern: /^.{0,200}$/,
        inputErrorMessage: '可输入不超过200字符',
        inputValidator: (value) => {
          this.value = value
        }
      }).then(async() => {
        const { code, msg } = await cancelReplenishContract(id, { id, cancelReason: this.value || '' })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.handleQuery()
        }
      })
    },
    deleteContract(id) {
      this.$confirm('请确认是否删除该条数据?', this.$t('page.Prompt'), {
        confirmButtonText: this.$t('page.sure')
      }).then(async() => {
        const { code, msg } = await delContract(id)
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
          this.handleQuery()
        }
      })
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.handleQuery()
    },
    handleQuery() {
      this.pager.current = 1
      this.queryClick()
    },
    async queryClick() {
      try {
        this.tableLoading = true
        const { code, datas: { pager, records }} = await replenishContractPage(this.queryParams)
        if (code === 0) {
          this.tableData = records.map(item => { return { ...item, numberLoading: false, signPrintTypeI18: item.signPrintType ? item.signPrintTypeI18 : '' } })
          this.pager = pager
        }
      } finally {
        this.tableLoading = false
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    }
  }
}
</script>

<style scoped lang="scss">
 /deep/ .el-date-editor .el-range-input{
    width:80px!important;
    }
</style>
