<template>
  <div>
    <el-dialog title="新增" :visible.sync="visible" width="500px" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" :rules="rules" label-width="140px">
        <el-form-item label="协议类型" prop="agreementType">
          <Select
            v-model="form.agreementType"
            :select-options="_getAllCommodityDict('SIGN_AGREEMENT_TYPE')"
            :configuration="optionsConfig"
            :clearable="false"
            @change="changeAgreementType"
          />
        </el-form-item>
        <el-form-item v-if="form.agreementType==1" label="合同类型" prop="contractType">
          <!-- 采购框架2 文件上传   1 form表单-->
          <Select
            v-model="form.contractType"
            :select-options="_getAllCommodityDict('SIGN_CONTRACT_TYPE')"
            :configuration="optionsConfig"
            @change="changeContractType"
          />
        </el-form-item>

        <el-form-item v-if="form.agreementType" label="合同编号" prop="contractCode">
          <el-select v-model="form.contractCode" value-key="id" placeholder="请选择" filterable clearable @change="changeContractCode">
            <el-option
              v-for="item in codeList"
              :key="item.id"
              :label="item.purchaseOrderContractCode||item.purchaseFrameworkContractCode"
              :value="item.purchaseOrderContractCode||item.purchaseFrameworkContractCode"
            />
          </el-select>
        </el-form-item>
        <!-- 解除协议+采购框架合同 -->
        <el-form-item v-if="form.agreementType==1&&form.contractType==2" label="框架合同失效日期" prop="frameworkContractExpiryDate">
          <el-date-picker
            v-model="form.frameworkContractExpiryDate"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          />
          <!-- @change="validateExpiryDate" -->
        </el-form-item>
        <el-form-item v-if="form.agreementType==2" label="采购订单" prop="notFufilledPoCodeList">
          <!-- <Select
            v-model="form.notFufilledPoCodeList"
            :select-options="poList"
            :configuration="optionsConfig"
            multiple
          /> -->
          <el-select
            v-model="form.notFufilledPoCodeList"
            multiple
            value-key="val"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in poList"
              :key="item.val"
              :label="item.label"
              :value="item.val"
            />
          </el-select>
        </el-form-item>
        <template>
          <el-form-item v-if="timeFlag" label="原合同签订时间" prop="originSignDate">
            <el-date-picker
              v-model="form.originSignDate"
              type="date"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item v-if="timeFlag" label="解除协议模板" prop="cancelTemplateType">
            <Select
              v-model="form.cancelTemplateType"
              :select-options="cancelTemplateTypeList"
              :configuration="optionsConfig"
              @change="changeTempType"
            />
          </el-form-item>
          <el-form-item v-if="form.cancelTemplateType==6" label="未履行采购订单" prop="notFufilledPoCodeList">
            <Select
              v-model="form.notFufilledPoCodeList"
              :select-options="poList"
              :configuration="optionsConfig"
              multiple
            />
          </el-form-item>
        </template>
        <el-form-item v-if="fileFlag" ref="file" prop="file" label="文件上传">
          <el-upload
            ref="upload"
            class="avatar-uploader"
            show-file-list
            :limit="1"
            action=""
            :auto-upload="false"
            :http-request="uploadFile"
            :on-remove="handleRemove"
            :before-upload="beforeAvatarUpload"
            accept=".pdf"
            :on-change="fileDateChange"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传PDF文件，且不超过10MB</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" :loading="submitLoading" @click="handleSubmit()">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// contractSaveNoFile
import { getFrameworkContractExpiryDateTip, contractSaveNoFile, contractSave, orderCodeList, frameCodeList, getPOContractLogList } from '@/api/scm-api'
import Select from '@/components/Selection'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { omit } from 'lodash'

export default {

  components: { Select },
  mixins: [commodityInfoDict],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    fileTypeList: {
      type: Array,
      default: () => []
    },
    row: {
      type: Object,
      default: () => {}
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const validateDate = async(rule, value, callback) => {
      const { agreementType, contractCode, contractType } = this.form
      if (!value) {
        callback(new Error('必填'))
      } else if (contractCode && agreementType && contractType) {
        const msg = await this.validateExpiryDate(value)
        msg ? callback(new Error(msg)) : callback()
      } else {
        callback()
      }
    }
    return {
      contractSave, contractSaveNoFile,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      cancelTemplateTypeList: [{ label: '未履行订单（全部）解除协议', val: '5' }, { label: '未履行订单（部分）解除协议', val: '6' }],
      form: { contractCode: '', file: '',
        notFufilledPoCodeList: [],
        frameworkContractExpiryDate: '', contractType: '', originSignDate: '' },
      fileList: [],
      submitLoading: false,
      codeList: [],
      poList: [],
      rules: {
        agreementType: { required: true, message: '必填', trigger: 'change' },
        contractType: { required: true, message: '必填', trigger: 'change' },
        contractCode: { required: true, message: '必填', trigger: 'change' },
        notFufilledPoCodeList: { required: true, message: '必填', trigger: 'change' },
        frameworkContractExpiryDate: [{ required: true, validator: validateDate, trigger: 'change' }],
        originSignDate: { required: true, message: '必填', trigger: 'change' },
        cancelTemplateType: { required: true, message: '必填', trigger: 'change' },
        file: { required: true, message: '必填', trigger: 'change' }
      }
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    fileFlag() {
      const { agreementType, contractType } = this.form
      return (agreementType === '1' && contractType === '2') || agreementType === '2'
    },
    timeFlag() {
      const { agreementType, contractType } = this.form
      return agreementType === '1' && contractType === '1'
    },

    saveParams() {
      if (this.fileFlag) {
        const formDataParams = new FormData()
        Object.keys(this.form).forEach((key) => formDataParams.set([key], this.form[key]))
        return formDataParams
      } else {
        return Object.assign({}, omit(this.form, 'file'))
      }
    },
    api() {
      return this.fileFlag ? this.contractSave : this.contractSaveNoFile
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.form = this.$options.data.call(this).form
      }
      this.$refs.form && this.$refs.form.clearValidate()
    }

  },
  created() {

  },
  mounted() {
    if (this.data) {
      this.form = this.data
      setTimeout(() => {
        this.getCodeListByPO()
        this.getPOList(this.data.contractCode)
      }, 100)
    }
  },
  methods: {
    async validateExpiryDate(frameworkContractExpiryDate) {
      const { agreementType, contractCode, contractType } = this.form
      const { datas } = await getFrameworkContractExpiryDateTip({ agreementType, contractCode, contractType, frameworkContractExpiryDate })
      return datas
    },
    changeTempType(val) {
      if (val === '6') {
        this.getPOList(this.form.contractCode)
      }
    },
    changeContractCode(val) {
      this.form.notFufilledPoCodeList = []
      this.form.frameworkContractExpiryDate = ''
      this.poList = []
      this.getPOList(val)
    },
    changeAgreementType(val) {
      this.codeList = []
      Object.assign(this.form, { contractType: '',
        contractCode: '', notFufilledPoCodeList: [], originSignDate: '' })
      this.handleRemove()
      this.$refs.upload?.clearFiles()

      if (val === '2') {
        this.getCodeListByPO()
      }
    },
    async getPOList(poContractCode) {
      const { datas } = await getPOContractLogList({ poContractCode })
      this.poList = datas.map(item => { return { ...item, label: item, val: item } })
    },
    changeContractType(val) {
      this.form.contractCode = ''
      this.codeList = []
      if (val) {
        if (val === '1') {
          this.getCodeListByPO()
        } else {
          this.getCodeListByFrame()
        }
      }
    },
    async getCodeListByPO() {
      const { datas } = await orderCodeList()
      this.codeList = datas
    },
    async getCodeListByFrame() {
      const { datas } = await frameCodeList()
      this.codeList = datas
    },
    handleSubmit() {
      this.$refs.form.validate(async valid => {
        console.log('valid: ', this.form, valid)
        if (valid) {
          // 解除协议+采购合同框架 新增二次校验
          if (this.form.agreementType + '' === '1' && this.form.contractType + '' === '2') {
            this.$confirm('请确认选择的【框架合同失效日期】与实际采购框架合同解除协议中的失效日期保持一致', this.$t('page.Prompt'), {
              confirmButtonText: this.$t('page.sure'),
              cancelButtonText: this.$t('title.cancel')
            }).then(() => {
              this.uploadFile()
            })
          } else {
            this.uploadFile()
          }
        }
      })
    },
    async uploadFile() {
      try {
        this.submitLoading = true
        const { code, msg } = await this.api(this.saveParams)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
          this.form.file = ''
          this.fileList = []
          this.$emit('input', false)
          this.$emit('refresh')
          this.$refs.upload.clearFiles()
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.submitLoading = false
      }
    },
    fileDateChange(file, fileList) {
      this.fileList = fileList
      this.form.file = file.raw
      this.$refs.file.clearValidate()
    },
    beforeAvatarUpload(file) {
      const types = ['application/pdf']
      const isJPG = types.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG) {
        this.$message.error('请上传PDF格式文件!')
      }
      if (!isLt2M) {
        this.$message.error('上传PDF文件大小不能超过 10MB!')
      }
      return isJPG && isLt2M
    },
    handleRemove(file, fileList) {
      this.form.file = ''
      this.fileList = []
    }

  }
}
</script>

<style scoped lang="scss">
  .fileType{
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/ .el-button--default{
      width: 200px;
    }
  }
</style>
